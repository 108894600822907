import React, { useEffect, useState } from 'react';
import { Button, Card, Form, message, Modal, Space, Table } from 'antd';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import BaseView from '../../components/BaseView';

const name = 'paneland';
function Paneland() {
  const [link, setLink] = useState([]);

  useEffect(() => {
    try {
      const json = JSON.parse(localStorage.getItem(`${name}_url_history`));
      setLink(json || []);
    } catch (e) {
      setLink([]);
    }
  }, []);

  const handleLink = (e) => {
    axios
      .get(`${name}/getLink`, { params: { ...e } })
      .then((res) => {
        setLink(res);
        if (res && res.length > 0)
          localStorage.setItem(`${name}_url_history`, JSON.stringify(res));
      })
      .catch((e) => {
        setLink([]);
      });
  };
  return (
    <BaseView name={name}>
      <Card
        title='操作'
        extra={
          <Space>
            <Button
              onClick={() => {
                window.open('/#/collection');
              }}
            >
              统计
            </Button>
            <Button
              onClick={() => {
                axios.get(`${name}/getIp`).then((res) => {
                  message.success(res);
                });
              }}
            >
              检测IP
            </Button>
            <Form
              onFinish={(e) => {
                setLink(null);
                handleLink(e);
              }}
              style={{ height: '32px' }}
            >
              <Space>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    type='primary'
                    loading={link === null}
                  >
                    获取问卷
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Space>
        }
      >
        <Table
          dataSource={link}
          rowKey={(record) => record.project_id}
          columns={[
            {
              title: 'ID',
              dataIndex: 'project_id',
              sorter: {
                compare: (a, b) => parseFloat(a.ID) - parseFloat(b.ID),
                multiple: 0,
              },
            },
            {
              title: '名称',
              dataIndex: 'name',
            },
            {
              title: '样本量',
              dataIndex: 'ss',
              sorter: {
                compare: (a, b) => parseFloat(a.ss) - parseFloat(b.ss),
                multiple: 3,
              },
            },
            {
              title: '要求时间',
              dataIndex: 'loi',
              sorter: {
                compare: (a, b) => parseFloat(a.loi) - parseFloat(b.loi),
                multiple: 2,
              },
            },
            {
              title: '地区/国家',
              dataIndex: 'country',
              sorter: {
                compare: (a, b) => a.country.localeCompare(b.country, 'zh-CN'),
                multiple: 5,
              },
            },
            {
              title: '金额',
              dataIndex: 'cpi',
              sorter: {
                compare: (a, b) => parseFloat(a.cpi) - parseFloat(b.cpi),
                multiple: 1,
              },
            },
            {
              title: '创建时间',
              dataIndex: 'itime',
              sorter: {
                compare: (a, b) =>
                  new Date(a.itime).valueOf() - new Date(b.itime).valueOf(),
                multiple: 1,
              },
            },
            {
              title: '操作',
              render: (value, record) => (
                <Space>
                  <Button
                    type='primary'
                    onClick={() => {
                      let text = record.pe
                        .map((item) => {
                          let _t = '<p>';
                          for (const key of Object.keys(item)) {
                            if (item[key] == null || !item[key]) continue;

                            switch (key) {
                              case 'City':
                                _t += `城市:${item[key]};`;
                                break;
                              case 'CompanySale':
                                _t += `公司销售:${item[key]};`;
                                break;
                              case 'Educational':
                                _t += `教育:${item[key]};`;
                                break;
                              case 'Gender':
                                _t += `性别:${item[key] == '0' ? '男' : '女'};`;
                                break;
                              case 'Householdincome':
                                _t += `家庭收入:${item[key]};`;
                                break;
                              case 'Industry':
                                _t += `行业:${item[key]};`;
                                break;
                              case 'Marital':
                                _t += `婚姻:${item[key]};`;
                                break;
                              case 'Occupation':
                                _t += `职业:${item[key]};`;
                                break;
                              case 'PersonalIncome':
                                _t += `个人收入:${item[key]};`;
                                break;
                              case 'Province':
                                _t += `省:${item[key]};`;
                                break;
                              case 'ageStart':
                                _t += `年龄范围开始:${item[key]};`;
                                break;
                              case 'ageend':
                                _t += `年龄范围结束:${item[key]};`;
                                break;
                            }
                          }
                          return _t + '</p>';
                        })
                        .join('\r');

                      Modal.info({
                        title: '配额',
                        content: (
                          <div dangerouslySetInnerHTML={{ __html: text }}></div>
                        ),
                        onOk() {},
                      });
                    }}
                  >
                    配额
                  </Button>
                  <Button
                    type='primary'
                    onClick={() => {
                      window.open(record.entry_link);
                    }}
                  >
                    打开
                  </Button>
                  <Button
                    onClick={() => {
                      copy(record.entry_link);
                      message.success('复制成功');
                    }}
                  >
                    复制
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      </Card>
    </BaseView>
  );
}
export default Paneland;
