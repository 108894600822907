import { message } from 'antd';
import axios from 'axios';
import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Collection from './pages/Home/Collection';
import Paneland from './pages/Home/Paneland';
import Login from './pages/Login';
const warning = (msg) => {
  message.warning({
    content: msg,
    key: msg,
  });
};
const router = new HashRouter();

axios.defaults.baseURL = 'https://pa.davidleesurvey.com/api/';

axios.interceptors.request.use((config) => {
  if (localStorage.getItem('token') != null) {
    config.headers['Authorization'] = localStorage.getItem('token');
  }
  return config;
});

axios.interceptors.response.use((res) => {
  if (res.status !== 200) return Promise.reject();
  try {
    const {
      data: { code, msg },
    } = res;
    if (code === 101) {
      warning(msg);
      return Promise.reject();
    }
    if (code === 100) {
      warning(msg);
      localStorage.removeItem('token');
      router.history.push('/login');
      return Promise.reject();
    }
    return Promise.resolve(msg);
  } catch (e) {
    warning('请求错误');
    return Promise.reject();
  }
});

function Routes() {
  return (
    <HashRouter>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/paneland' component={Paneland} />
        <Route path='/collection' component={Collection} />
        <Route path='/' render={() => <Redirect to='/login' />} />
      </Switch>
    </HashRouter>
  );
}
export default Routes;
